import styled from 'styled-components';
import { Layout, Menu } from 'antd';
import variables from '../../../styles/variables.module.scss';

const { Sider } = Layout;

export const StyledSider = styled(Sider)`
  &&& {
    background: linear-gradient(to bottom, ${variables.primaryColor} 100%, #2a454e 100%);
    color: white;
    // Adjustments for collapsed state
    ${(props) =>
      props.collapsed &&
      `
      min-width: 49px !important;
      flex: 0 0 49px !important;
      max-width: 49px !important;
      width: 49px !important;
      .trigger {
        font-size: 18px;  // Adjust icon size if necessary
      }
    `}
  }
`;

export const StyledMenu = styled(Menu)`
  background: linear-gradient(to bottom, ${variables.primaryColor} 100%, #2a454e 100%);
  border-color: red;
  color: white;
  height: calc(100vh - 64px -54px);

  && .ant-menu-item {
    color: white;
    opacity: 0.8;

    &:active {
      background-color: transparent;
    }
    &:not(.ant-menu-item-selected):active {
      background-color: transparent;
    }
    &:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):hover {
      color: white;
      opacity: 1;
      background-color: transparent;
    }
  }

  && .ant-menu-item-selected {
    background-color: ${variables.primaryColorHover};
    border-radius: 0;
    &:hover {
      opacity: 1;
    }
  }

  && .ant-menu-item-selected a {
    color: white;
  }
`;
