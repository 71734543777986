import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'components/common/Button/Button';
import Input from 'components/common/Input/Input';
import { StyledFormItem } from 'components/common/Input/Input.styles';
import { Form } from 'antd';
import styles from './ResetPassword.module.scss';
import { useLogoutMutation, useResetPasswordMutation } from 'services/auth.service';
import { Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { User } from 'interfaces/auth/User';
import { setUser } from 'store/slices/auth.slice';
import useTranslation from 'hooks/useTranslations';

interface ResetPasswordInterface {
  hasLogout?: boolean;
}

const ResetPassword: React.FC<ResetPasswordInterface> = ({ hasLogout = false }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [resetPassword] = useResetPasswordMutation();
  const [logout] = useLogoutMutation();
  const { user } = useSelector((state: any) => state.auth) as { user: User };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useTranslation();

  const errorInfo = translate('tr_password_format_error');
  function isStongPassword(password: string): boolean {
    const minLength = 10;
    const hasMinLength = password.length >= minLength;
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSymbol = /[^a-zA-Z0-9]/.test(password);

    return hasMinLength && hasLowercase && hasUppercase && hasNumber && hasSymbol;
  }

  const handleSubmit = async () => {
    if (currentPassword === newPassword) {
      setError(translate('tr_password_same_error'));
      return;
    } else {
      setError(null);
    }

    if (newPassword !== confirmPassword) {
      setError(translate('tr_password_match_error'));
      return;
    } else {
      setError(null);
    }

    if (isStongPassword(newPassword) === false) {
      setError(translate('tr_password_strong_error'));
      return;
    } else {
      setError(null);
    }

    const token = localStorage.getItem('token');
    const response = (await resetPassword({
      username: user.userName,
      password: currentPassword,
      newPassword,
      token,
    })) as any;
    if (response.error) {
      if (response.error.data.error === 'Unauthorized') {
        setError(translate('tr_password_incorect_error'));
      }
    } else {
      let newUser = { ...user, firstLogin: false };
      dispatch(setUser(newUser));
      navigate('/');
    }
  };

  const onLogout = () => {
    try {
      logout({});
      dispatch(setUser(null));
      navigate('/login');
    } catch (error: any) {
      console.log('Error logging out', error);
    }
  };

  const onFinishFailed = (errorInfo: string) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name='basic'
      initialValues={{ remember: true }}
      onFinish={handleSubmit}
      onFinishFailed={() => onFinishFailed}
      className={styles.resetForm}
    >
      <StyledFormItem
        name='currentPassword'
        rules={[{ required: true, message: translate('tr_passwrod_missing') }]}
      >
        <Input.Password
          placeholder={translate('tr_current_password')}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
      </StyledFormItem>
      <StyledFormItem
        name='newPassword'
        rules={[{ required: true, message: translate('tr_passwrod_missing') }]}
      >
        <Input.Password
          placeholder={translate('tr_new_password')}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </StyledFormItem>
      <StyledFormItem
        name={translate('tr_confirm_password')}
        rules={[{ required: true, message: translate('tr_passwrod_missing') }]}
      >
        <Input.Password
          placeholder={translate('tr_confirm_password')}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </StyledFormItem>
      <div className={styles.actionContainer}>
        <Button primary fullWidth htmlType='submit'>
          {translate('tr_set_password')}
        </Button>
      </div>
      {error && <Alert message={error} type='error' showIcon />}
      {error === 'Password not strong enough' && <p className={styles.errorInfo}>{errorInfo}</p>}
      {hasLogout && (
        <>
          <div className={styles.divider}></div>
          <div className={styles.logoutContainer}>
            <p className={styles.smallText}>Logged in as: {user.userName} </p>
            <p className={styles.link} onClick={() => onLogout()}>
              {translate('tr_logout')}
            </p>
          </div>
        </>
      )}
    </Form>
  );
};
export default ResetPassword;
