import { CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import styles from './DeviceStatus.module.scss';
import { Tooltip } from 'antd';

import { Device } from 'interfaces/devices/Devices.interface';
import useTranslation from 'hooks/useTranslations';
import { createDeviceUtils } from 'utils/device.utils';
import useIsMobile from 'hooks/useIsMobile';

interface DeviceStatusProps {
  device: Device | number | null;
}

export const DeviceStatus: React.FC<DeviceStatusProps> = ({ device }) => {
  const { translate } = useTranslation();
  const deviceUtils = createDeviceUtils(translate);
  const isMobile = useIsMobile();
  const isMobileClass = isMobile ? 'isMobile' : '';

  const messageWarningTooltip = (
    <div className={styles.devicesOfflineWarning}>
      <p>{translate('tr_device_offline1')} </p>
      <p>{translate('tr_device_offline2')} </p>
    </div>
  );
  const deviceOnlineBadge = (
    <span className={`${styles.deviceOnlineBadge} ${isMobileClass ? styles.isMobile : ''}`}>
      <CheckCircleFilled />
      <span className={styles.badgeText}>{translate('tr_online')}</span>
    </span>
  );

  const deviceOfflineBadge = (
    <Tooltip placement='bottom' title={messageWarningTooltip} arrow={false} color='white'>
      <span className={`${styles.deviceOfflineBadge} ${isMobileClass ? styles.isMobile : ''}`}>
        <WarningFilled />
        <span className={styles.badgeText}>{translate('tr_offline')}</span>
      </span>
    </Tooltip>
  );

  return deviceUtils.isDeviceOffline(device) ? deviceOfflineBadge : deviceOnlineBadge;
};
