import { useGetTimelineQuery } from 'services/timeline.service';
import { useAppSelector } from 'store/hooks';
import { tenantSelector } from 'store/slices/tenants.slice';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Button, Result, Spin, Timeline as AntTimeline } from 'antd';
import dayjs from 'dayjs';
import styles from './Timeline.module.scss';
import { styled } from 'styled-components';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useState, useEffect } from 'react';
import Spinner from 'components/common/Spin/Spin';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as ActuatorIllustration } from 'assets/icons/actuators_illustration2.svg';
import useTranslation from 'hooks/useTranslations';

const StyledTimeline = styled(AntTimeline)<{ mode: 'left' | 'right' | 'alternate'; items: any[] }>`
  &&.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
    inset-inline-start: 170px;
  }
  &&.ant-timeline.ant-timeline-label .ant-timeline-item-head {
    inset-inline-start: 170px;
  }
  &&.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
    inset-inline-start: 170px;
  }
  &&.ant-timeline.ant-timeline-label .ant-timeline-item-label {
    width: 145px;
    position: absolute;
    inset-block-start: -8px;
    text-align: end;
    padding: 8px;
    padding-right: 0;
    border-left: 0;
  }
  &&.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
    width: calc(100% - 230px);
  }
  &&.ant-timeline .ant-timeline-item-head {
    position: absolute;
    width: 16px;
    height: 16px;
    margin-inline-start: -7px;
    top: 25%;
    margin-top: -8px;
    border-width: 8px !important;
  }
  &&.ant-timeline .ant-timeline-item-tail {
    height: 100%;
  }
  &&:where(.css-dev-only-do-not-override-tjopz4).ant-timeline .ant-timeline-item-head{
      border: 8px solid transparent;
  }
  &&:where(.css-dev-only-do-not-override-tjopz4).ant-timeline .ant-timeline-item-head-green {
      border-color: #52c41a
  }
  &&:where(.css-dev-only-do-not-override-tjopz4).ant-timeline .ant-timeline-item-head-gray {
      border-color: rgba(0, 0, 0, 0.25)
  }
  && .ant-timeline-item-head.ant-timeline-item-head-custom.ant-timeline-item-head-blue{
     margin-left: 0;
     svg {
        font-size: 18px;
     }
  }
  @media (max-width: 900px) {
    &&.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
    inset-inline-start: 30px;
  }
  &&.ant-timeline.ant-timeline-label .ant-timeline-item-head {
    inset-inline-start: 30px;
  }
  &&.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
    inset-inline-start: 30px;
  }
  &&.ant-timeline.ant-timeline-label .ant-timeline-item-label {
    width: 145px;
    position: absolute;
    inset-block-start: -12px;
    text-align: end;
    padding: 8px;
    padding-right: 0;
    border-left: 0;
  }
  &&.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
    width: calc(100% - 80px);
  }
  }
}`;

const Timeline = () => {
  const tenantId = useAppSelector(tenantSelector).selectedTenant?.tenantId as string;
  const [timelineData, setTimelineData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [timelineItems, setTimelineItems] = useState<any[]>([]);
  const [currentTenantId, setCurrentTenantId] = useState<string | null>(null);
  const { translate } = useTranslation();

  const {
    data: timeline,
    isLoading,
    error,
  } = useGetTimelineQuery({ tenantId, page }, { skip: !tenantId, refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (tenantId !== currentTenantId) {
      setCurrentTenantId(tenantId);
      setTimelineData([]);
      setHasMore(true);
      setTimelineItems([]);
      setPage(1);
    }
  }, [tenantId, currentTenantId]);

  useEffect(() => {
    if (timeline?.data) {
      setTimelineData((prev) => {
        const existingIds = new Set(prev.map((item) => item.id));
        const newItems = timeline.data.filter((item: { id: any }) => !existingIds.has(item.id));
        const mergedItems = [...newItems, ...prev].sort((a, b) => b.timestamp - a.timestamp);
        return mergedItems;
      });
      setHasMore(timeline?.hasMore || false);
    }
  }, [timeline]);

  const renderEmptyTimeline = () => {
    return (
      <div className='emptyContainer'>
        <ActuatorIllustration className='actuatorIllustration' />
        <div className='emptyContent'>
          <p className='emptyTitle'>{translate('tr_no_actuator_activity_title')}</p>
          <p className='emptyDescription'>{translate('tr_no_actuator_activity_description')}</p>
          <Link to='/actuators'>
            <Button className={styles.tenantSelector}>
              {translate('tr_no_actuator_activity_action')}
            </Button>
          </Link>
        </div>
      </div>
    );
  };

  useEffect(() => {
    let month = '';
    const items = timelineData.flatMap((item) => {
      const currentMonth = dayjs.unix(item.timestamp).format('MMMM YYYY');
      const dayAndMonth = dayjs.unix(item.timestamp).format('DD.MMM');
      const time = dayjs.unix(item.timestamp).format('HH:mm');
      const content = (
        <div
          className={`${styles.timelineRow} ${styles[item.actuator_status === 1 ? 'contentOn' : 'contentOff']}`}
        >
          <div className={styles.timelineTime}>
            <span>{`${dayAndMonth} ${time}`}</span>
          </div>
          <div className={styles.timelineText}>
            {translate('tr_turned')}{' '}
            {item.actuator_status === 1 ? translate('tr_on') : translate('tr_off')}{' '}
            <strong>{item.actuator_name}</strong> {translate('tr_on_device')}{' '}
            <strong>{item.device_name}</strong>
          </div>
        </div>
      );

      if (currentMonth !== month) {
        month = currentMonth;
        return [
          {
            dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
            label: currentMonth,
            children: <div className={styles.hiddenChildren}>{currentMonth}</div>,
          },
          {
            children: content,
            color: item.actuator_status === 1 ? 'green' : 'gray',
          },
        ];
      }

      return {
        children: content,
        color: item.actuator_status === 1 ? 'green' : 'gray',
      };
    });
    setTimelineItems(items);
  }, [timelineData, translate]);

  const fetchMoreData = () => {
    if (!hasMore) return;
    setPage((prevPage) => prevPage + 1);
  };

  const hasTimeline = timelineData?.length > 0;

  if (isLoading) {
    return (
      <div className={styles.timelineContainer}>
        <div className={styles.spinnerContainer}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      </div>
    );
  }

  return (
    <>
      {!hasTimeline && !error && renderEmptyTimeline()}
      {hasTimeline && !error && (
        <div className={styles.timelineContainer}>
          <InfiniteScroll
            dataLength={timelineData.length}
            next={fetchMoreData}
            hasMore={hasMore}
            scrollableTarget='scrollableContent'
            loader={
              <div className={styles.spinnerInfiniteScroll}>
                <Spinner spinning={true} children={undefined}></Spinner>
              </div>
            }
            endMessage={<p style={{ textAlign: 'center' }}></p>}
            style={{
              overflow: 'unset',
              height: 'auto',
            }}
          >
            <StyledTimeline mode='left' items={timelineItems} />
          </InfiniteScroll>
        </div>
      )}
      {error && <Result status='500' subTitle={translate('tr_no_timeline')} />}
    </>
  );
};
export default Timeline;
