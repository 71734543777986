import dayjs from 'dayjs';
import { deviceParametersGroup, deviceTypes, powerStatus, timeSeconds } from 'config/constant';
import { Device } from 'interfaces/devices/Devices.interface';
import { DeviceEvent } from 'interfaces/devices/DeviceEvents.interface';

/**
 * Higher-order utility wrapper for device-related utilities.
 * Includes all utility functions, with an optional translation function (t) for internationalization of text.
 *
 * @param {Function} t - Translation function for localization.
 * @returns {Object} - Utility functions for device operations.
 */
export const createDeviceUtils = (translate: (key: string) => string = (key) => key) => {
  /**
   * Checks if the device is of type 'sensor'.
   * @param {Device} device - The device object to check.
   * @returns {boolean} True if the device type is 'sensor', otherwise false.
   */
  const isDeviceSensor = (device: Device): boolean => {
    return device.deviceType === deviceTypes.SENSOR;
  };

  /**
   * Checks if the device is of type 'switch'.
   * @param {Device} device - The device object to check.
   * @returns {boolean} True if the device type is 'switch', otherwise false.
   */
  const isDeviceSwitch = (device: Device): boolean => {
    return device.deviceType === deviceTypes.SWITCH;
  };

  /**
   * Checks if the device is offline based on the last seen time.
   * A device is considered offline if it hasn't been seen in the last 15 minutes.
   * @param {Device | null | undefined | number} input - Device object or timestamp to check.
   * @returns {boolean} True if the device is offline, otherwise false.
   */
  const isDeviceOffline = (input: Device | null | undefined | number): boolean => {
    if (!input) return true;
    const currentTimeInSeconds = Date.now() / 1000;
    const lastSeenTimeInSeconds =
      typeof input === 'number' ? input : input?.lastSeenAt?.seconds?.low;

    if (!lastSeenTimeInSeconds) return true;

    const timeDifference = currentTimeInSeconds - lastSeenTimeInSeconds;

    return timeDifference > timeSeconds.QUARTER_HOUR;
  };

  /**
   * Returns a tooltip message based on the device's online/offline status.
   * @param {boolean} isOffline - Indicates whether the device is offline.
   * @returns {string} Tooltip message for device status.
   */
  const getDeviceStatusTooltipMessage = (isOffline: boolean): string => {
    return isOffline
      ? translate('tr_device_status_offline') || 'This device is not sending messages.'
      : translate('tr_device_status_active') || 'This device is actively sending update messages.';
  };

  /**
   * Determines the power status of a device based on its battery level.
   * @param {Device | null | undefined} device - Device object to analyze.
   * @returns {string} Device power status.
   */
  const getPowerStatus = (device: Device | null | undefined): string => {
    if (!device) return powerStatus.POWER_NO_INFO;

    const batteryLevel = Number(device.latestEvent?.parameters?.battery) || null;

    if (!batteryLevel) return powerStatus.POWER_NO_INFO;
    if (batteryLevel > 100) return powerStatus.POWER_CABLE;
    else if (batteryLevel > 60) return powerStatus.BATTERY_HIGH;
    else if (batteryLevel <= 60 && batteryLevel > 25) return powerStatus.BATTERY_MEDIUM;
    else if (batteryLevel > 0) return powerStatus.BATTERY_LOW;
    else return powerStatus.BATTERY_EMPTY;
  };

  /**
   * Returns a tooltip message for the device's power status.
   * @param {string} devicePowerStatus - The power status of the device.
   * @returns {string} Tooltip message for power status.
   */
  const getDevicePowerTooltipMessage = (devicePowerStatus: string): string => {
    switch (devicePowerStatus) {
      case powerStatus.BATTERY_HIGH:
      case powerStatus.BATTERY_MEDIUM:
        return translate('tr_device_power_usable') || 'This device is charged and usable';
      case powerStatus.BATTERY_LOW:
        return translate('tr_device_power_low') || 'This device is low on battery';
      case powerStatus.BATTERY_EMPTY:
        return translate('tr_device_power_empty') || 'This device has run out of battery';
      case powerStatus.POWER_CABLE:
        return translate('tr_device_power_cable') || 'This device is connected to a power source';
      default:
        return translate('tr_device_power_no_info') || 'This device has no battery information yet';
    }
  };

  /**
   * Returns the uptime tooltip message for a device.
   * @param {boolean} hasUptime - Whether the device has uptime information.
   * @returns {string} Tooltip message for uptime.
   */
  const getDeviceUptimeTooltipMessage = (hasUptime: boolean): string => {
    return hasUptime
      ? translate('tr_device_uptime_available') || 'The device is active for this time period'
      : translate('tr_device_uptime_unavailable') || 'Device is offline, uptime is unavailable';
  };

  /**
   * Returns the last seen timestamp of the device.
   * Optionally includes relative time from now.
   * @param {Device | number | null | undefined} input - Device object or timestamp.
   * @param {boolean} showFromNow - Whether to show relative time.
   * @returns {string} Last seen message.
   */
  const getDeviceLastSeen = (
    input: Device | null | undefined | number,
    showFromNow: boolean
  ): string => {
    let lastSeenUnix = null;
    if (typeof input === 'number') {
      lastSeenUnix = input;
    } else if (input) {
      lastSeenUnix = input.lastSeenAt?.seconds?.low;
    }

    const lastSeenAtMessage = lastSeenUnix
      ? dayjs.unix(lastSeenUnix).format('YYYY-MM-DD HH:mm:ss')
      : translate('tr_device_never_seen') || 'Never';
    const timeFromNow =
      showFromNow && lastSeenUnix ? ` (${dayjs.unix(Number(lastSeenUnix)).fromNow()})` : '';

    return lastSeenAtMessage + timeFromNow;
  };

  /**
   * Calculates the uptime for a device.
   * @param {Device | null | undefined} device - The device object.
   * @param {number | undefined} lastOfflineTimestamp - Timestamp when the device was last offline.
   * @returns {string} Formatted uptime string.
   */
  const getUptime = (device?: Device | null, lastOfflineTimestamp?: number): string => {
    const isOffline = isDeviceOffline(device);

    if (isOffline || !device || !lastOfflineTimestamp) return '-';

    const currentTime = dayjs().unix();
    const upTime = currentTime - Number(lastOfflineTimestamp);

    const totalTimeDays = Math.floor(upTime / (3600 * 24));
    const totalTimeHours = Math.floor((upTime % (3600 * 24)) / 3600);
    const totalTimeMinutes = Math.floor((upTime % 3600) / 60);

    let uptimeString = '';
    if (totalTimeDays > 0) {
      uptimeString += `${totalTimeDays} ${totalTimeDays > 1 ? translate('tr_day_pl') : translate('tr_day')}`;
    }
    if (totalTimeHours > 0) {
      uptimeString += ` ${totalTimeHours} ${totalTimeHours > 1 ? translate('tr_hour_pl') : translate('tr_hour')}`;
    }
    if (totalTimeMinutes > 0) {
      uptimeString += ` ${totalTimeMinutes} ${totalTimeMinutes > 1 ? translate('tr_minute_pl') : translate('tr_minute')}`;
    }

    return upTime > 0 ? uptimeString : '';
  };

  const getDeviceSensors = (deviceEvents: DeviceEvent[]) => {
    let deviceSensors: string[] = [];
    let isPowerPlug = false;
    deviceEvents.forEach((event: DeviceEvent) => {
      if (event.parameters) {
        const parameters = Object.keys(event.parameters);
        if (
          parameters.includes(deviceParametersGroup.BATTERY) &&
          event?.parameters?.battery != null &&
          event.parameters.battery > 100
        ) {
          isPowerPlug = true;
        }
        deviceSensors = [...deviceSensors, ...parameters];
      }
    });
    let paramtersSet = Array.from(new Set(deviceSensors));
    let sensors = paramtersSet.filter((sensor) => sensor !== deviceParametersGroup.ACTUATORS);
    if (isPowerPlug) {
      sensors = sensors.filter((sensor) => sensor !== deviceParametersGroup.BATTERY);
    }

    return sensors;
  };

  const getSensorVersion = (deviceEvent: any) => {
    if (deviceEvent?.parameters?.temperature) {
      return 'V2';
    } else if (deviceEvent?.parameters?.temperatures) {
      return 'V3';
    }
    return null;
  };

  const hasActuators = (deviceEvents: DeviceEvent[]) => {
    let hasActuators = false;
    deviceEvents.forEach((event) => {
      if (event.parameters.actuators) {
        hasActuators = true;
      }
    });
    return hasActuators;
  };

  return {
    isDeviceSensor,
    isDeviceSwitch,
    isDeviceOffline,
    getDeviceStatusTooltipMessage,
    getPowerStatus,
    getDevicePowerTooltipMessage,
    getDeviceUptimeTooltipMessage,
    getDeviceLastSeen,
    getUptime,
    getDeviceSensors,
    hasActuators,
    getSensorVersion,
  };
};
