import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';

interface LanguageState {
  language: string | null;
}

const initialState: LanguageState = {
  language: null,
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload;
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export const langageSelector = (state: RootState) => state.language as LanguageState;

export default languageSlice.reducer;
