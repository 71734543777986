import { baseApi } from './baseApi.service';

export const userService = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    setLanguagePerUser: builder.mutation<any, { id: string; language: string }>({
      query: ({ id, language }) => ({
        url: `/users/language/${id}`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ language }),
        credentials: 'include',
      }),
      transformResponse: (response: any) => {
        const result = response || null;

        return result;
      },
    }),
  }),
});

export const { useSetLanguagePerUserMutation } = userService;
