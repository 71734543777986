import { ReactComponent as Overview } from 'assets/icons/overview.svg';
import { ReactComponent as Light } from 'assets/icons/light.svg';
import { ReactComponent as AirHumidity } from 'assets/icons/air-humidity.svg';
import { ReactComponent as Battery } from 'assets/icons/battery-charging.svg';
import { ReactComponent as SoilMoisture } from 'assets/icons/soil-moisture.svg';
import { ReactComponent as Pressure } from 'assets/icons/pressure.svg';
import { ReactComponent as Temperature } from 'assets/icons/temperature.svg';
import { ReactComponent as FlowRate } from 'assets/icons/flow-meter.svg';
import styles from './GraphTabs.module.scss';
import { DEVICE_VERSION, deviceParametersV2, deviceParametersV3 } from 'config/constant';

export const getGraphTab = (
  deviceVersion: string | null,
  parameters: string[],
  t: (key: string) => string
) => {
  let deviceParam = deviceVersion === DEVICE_VERSION.V3 ? deviceParametersV3 : deviceParametersV2;

  const graphTabs = [
    {
      label: t('tr_overview'),
      key: '1',
      graphParameter: 'parameters.' + deviceParam.LUMINOSITY,
      graphLabel: '',
      icon: <Overview />,
      className: 'overviewTab',
    },
    {
      label: t('tr_luminosity'),
      key: '2',
      graphParameter: 'parameters.' + deviceParam.LUMINOSITY,
      graphLabel: t('tr_luminosity'),
      icon: <Light className={styles.tabIcon} />,
      className: 'luminosity',
    },
    {
      label: t('tr_temperature'),
      key: '3',
      graphParameter: 'parameters.' + deviceParam.TEMPERATURE,
      graphLabel: t('tr_temperature'),
      icon: <Temperature className={styles.tabIcon} />,
      className: 'temperature',
    },
    {
      label: t('tr_soil_moisture'),
      key: '4',
      graphParameter: 'parameters.' + deviceParam.SOIL_MOISTURE,
      graphLabel: t('tr_soil_moisture'),
      icon: <SoilMoisture className={styles.tabIcon} />,
      className: 'soilMoisture',
    },
    {
      label: t('tr_pressure'),
      key: '5',
      graphParameter: 'parameters.' + deviceParam.PRESSURE,
      graphLabel: t('tr_pressure'),
      icon: <Pressure className={styles.tabIcon} />,
      className: 'pressure',
    },
    {
      label: t('tr_air_humidity'),
      key: '6',
      graphParameter: 'parameters.' + deviceParam.AIR_HUMIDITY,
      graphLabel: t('tr_air_humidity'),
      icon: <AirHumidity className={styles.tabIcon} />,
      className: 'airHumidity',
    },
    {
      label: t('tr_battery'),
      key: '7',
      graphParameter: 'parameters.' + deviceParam.BATTERY,
      graphLabel: t('tr_battery'),
      icon: <Battery className={styles.tabIcon} />,
      className: 'battery',
    },
    {
      label: t('flow_rate'),
      key: '8',
      graphParameter: 'parameters.' + deviceParam.FLOW_RATES,
      graphLabel: t('flow_rate'),
      icon: <FlowRate className={styles.tabIcon} />,
      className: 'flowRate',
    },
  ];

  return graphTabs.filter((tab) =>
    parameters.includes(tab.graphParameter.replace('parameters.', ''))
  );
};
