import useTranslation from 'hooks/useTranslations';
import { ReactComponent as LanguageIcon } from 'assets/icons/language.svg';
import styles from './LanguageSelector.module.scss';
import { Dropdown, MenuProps } from 'antd';

export const LanguageSelector = () => {
  const { language, changeLanguage, getLanguageTitle } = useTranslation();

  const items: MenuProps['items'] = [
    {
      label: 'Romanian',
      key: 'ro',
    },
    {
      label: 'English',
      key: 'en',
    },
    {
      label: 'Magyar',
      key: 'hu',
    },
  ];

  const onClick = ({ key }: { key: string }) => {
    changeLanguage(key);
  };

  return (
    <div className={styles.languageSelector}>
      <Dropdown menu={{ items, onClick }}>
        <div onClick={(e) => e.preventDefault()}>
          <LanguageIcon className={styles.languageSelectorIcon} />
          <div className={styles.languageText}>{getLanguageTitle(language)}</div>
        </div>
      </Dropdown>
    </div>
  );
};

export default LanguageSelector;
