/* eslint-disable array-callback-return */
import { useMemo, useRef } from 'react';
import {
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Brush,
  AreaChart,
  Area,
  ResponsiveContainer,
  ReferenceDot,
} from 'recharts';
import dayjs from 'dayjs';
import styles from './Graph.module.scss';
import { getUnit } from 'utils/graph.utils';
import stylesVariables from 'styles/variables.module.scss';
import { CurveType } from 'recharts/types/shape/Curve';

import useUrlParams from 'hooks/useUrlParams';
import { useZoomAndPan } from 'hooks/useZoomAndPan';
import { useFilteredEvents } from 'hooks/useFilteredEvents';
import { parameterLevelColors } from 'config/constant';
import { uniqueId } from 'lodash';
import { toFixed } from 'utils/math.utils';
import useTranslations from 'hooks/useTranslations';

declare global {
  interface Window {
    zoomTimeout?: number;
  }
}

type GraphProps = {
  eventsList: any[];
  parameter: string;
  parameterLabel: string;
  zoomDomain: [number, number] | null;
  setZoomDomain: (domain: [number, number] | null) => void;
  urlParams: any;
  ticks: number[];
  isDot: boolean;
  setIsDot: (value: boolean) => void;
  getTickFormatter: (timestamp: number) => string;
  getBrushClass: () => string;
  hasBrush?: boolean;
  hasXAxis?: boolean;
  hasYAxis?: boolean;
  hasTooltip?: boolean;
  graphMargin?: { top: number; right: number; left: number; bottom: number };
  style?: React.CSSProperties;
  disableWheel?: boolean;
  disableMouseEvents?: boolean;
  showPointRef?: boolean;
  graphLineStyle?: CurveType;
  pointRefIndex?: number[] | null;
};

const Graph: React.FC<GraphProps> = ({
  eventsList,
  parameter,
  parameterLabel,
  zoomDomain,
  setZoomDomain,
  ticks,
  isDot,
  setIsDot,
  getTickFormatter,
  getBrushClass,
  hasBrush = true,
  hasXAxis = true,
  hasYAxis = true,
  hasTooltip = true,
  graphMargin = { top: 50, right: 30, left: 0, bottom: 50 }, // Default margin
  style,
  disableWheel = false,
  disableMouseEvents = false,
  showPointRef = false,
  graphLineStyle = 'monotone',
  pointRefIndex = null,
}) => {
  const [urlParams, updateUrlParams] = useUrlParams();
  const { translate } = useTranslations();

  const zoomDomainUrl = useMemo(() => {
    return urlParams.zoomDomainMin && urlParams.zoomDomainMax
      ? [urlParams.zoomDomainMin, urlParams.zoomDomainMax]
      : null;
  }, [urlParams.zoomDomainMin, urlParams.zoomDomainMax]);

  const activeZoomDomain = zoomDomain || zoomDomainUrl;

  const { numericEventsList, startIndex, endIndex } = useFilteredEvents(
    eventsList,
    activeZoomDomain
  );
  const hasBrushBeenUsed = useRef(false);

  const processedData = numericEventsList.map((entry) => {
    const newEntry = { ...entry };
    const paramName = parameter.replace('parameters.', '');

    if (Array.isArray(entry.parameters[paramName])) {
      entry.parameters[paramName].forEach((value: any, index: any) => {
        newEntry[`${paramName}_${index}`] = value;
      });
    } else {
      newEntry[paramName] = entry.parameters[paramName];
    }

    return newEntry;
  });
  const brushIndicesRef = useRef<{ startIndex: number | null; endIndex: number | null }>({
    startIndex: null,
    endIndex: null,
  });

  const { lineChartWrapperRef } = useZoomAndPan({
    eventsList,
    activeZoomDomain: zoomDomain,
    setZoomDomain,
    updateUrlParams,
    disableWheel,
    disableMouseEvents,
    setIsDot,
    brushIndicesRef,
    hasBrushBeenUsed,
  });

  const graphUnit = getUnit(parameter);
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.customTooltip}>
          <div className={styles.customTooltipLabel}>
            {payload.map((_entry: any, index: string | number) => (
              <p key={`tooltip-${index}`}>
                <span
                  className={styles.customTooltipLabelMark}
                  style={{ backgroundColor: payload[index].color }}
                ></span>
                {`${toFixed(payload[index].value, 1)} ${getUnit(payload[index].dataKey)}`}
              </p>
            ))}
          </div>
          <p className={styles.customTooltipFooter}>
            {`${translate('tr_time')}: ${dayjs.unix(label).format('YYYY-MM-DD HH:mm:ss')}`}
          </p>
        </div>
      );
    }
    return null;
  };

  const CustomDot = (props: any) => {
    const { cx, cy, color } = props;
    if (!cy || !cx) return null;
    return <circle cx={cx} cy={cy} r={8} stroke={'#fff'} strokeWidth={2} fill={color} />;
  };

  return (
    <div style={{ height: '100%' }} className={styles.tabPaneLoading} ref={lineChartWrapperRef}>
      {processedData.length > 0 && (
        <ResponsiveContainer width='100%' height='100%'>
          <AreaChart
            data={processedData}
            key={urlParams.tab}
            margin={graphMargin}
            style={{ ...style }}
          >
            {hasYAxis && hasXAxis && <CartesianGrid strokeDasharray='3 3' stroke='#f0f0f0' />}
            {!hasYAxis && !hasXAxis && <CartesianGrid vertical={false} horizontal={false} />}
            <XAxis
              dataKey='timestamp'
              tickFormatter={(timestamp) => getTickFormatter(timestamp)}
              minTickGap={70}
              ticks={ticks}
              style={{ opacity: hasXAxis ? 1 : 0 }}
            />
            <YAxis
              tickFormatter={(value) => Math.round(value).toString()}
              type='number'
              unit={graphUnit}
              domain={([dataMin, dataMax]) => {
                const min = Math.min(dataMin);
                const max = Math.max(dataMax);
                const threshold = (max - min) * 0.05;
                const newMin = min - threshold < 0 ? 0 : min - threshold;
                let domainMax = max + threshold;
                if (graphUnit === '%') domainMax = max;
                return [newMin, domainMax];
              }}
              axisLine={hasYAxis}
              hide={!hasYAxis}
            />
            {hasTooltip && isDot && (
              <Tooltip content={<CustomTooltip />} isAnimationActive={false} />
            )}
            <Legend payload={[{ value: parameterLabel, type: 'line', id: 'ID01' }]} />

            {Object.keys(processedData[0].parameters || {}).map((key, index) => {
              if (Array.isArray(processedData[0].parameters[key])) {
                return processedData[0].parameters[key].map((_: any, subIndex: any) => {
                  const colorIndex = subIndex % parameterLevelColors.length;
                  const color = parameterLevelColors[colorIndex];
                  const gradientId = `colorGradient_${key}_${subIndex}`;

                  return (
                    <>
                      <defs key={`gradient_${key}_${subIndex}`}>
                        <linearGradient id={gradientId} x1='0' y1='0' x2='0' y2='1'>
                          <stop offset='0%' stopColor={color} stopOpacity={0.2} />
                          <stop offset='100%' stopColor={color} stopOpacity={0} />
                        </linearGradient>
                      </defs>
                      <Area
                        key={`${key}_${subIndex}`}
                        type={graphLineStyle}
                        dataKey={`${key}_${subIndex}`}
                        stroke={color}
                        fill={`url(#${gradientId})`}
                        fillOpacity={0.6}
                        strokeWidth={1.5}
                        activeDot={isDot ? <CustomDot color={color} /> : false}
                        isAnimationActive={false}
                      />
                    </>
                  );
                });
              } else {
                // Handle single-value parameters - single area
                const gradientId = `colorGradient_${key}`;
                const color = parameterLevelColors[0];
                return (
                  <>
                    <defs key={`gradient_${key}`}>
                      <linearGradient id={gradientId} x1='0' y1='0' x2='0' y2='1'>
                        <stop
                          offset='0%'
                          stopColor={stylesVariables.primaryColor}
                          stopOpacity={0.2}
                        />
                        <stop
                          offset='100%'
                          stopColor={stylesVariables.primaryColor}
                          stopOpacity={0}
                        />
                      </linearGradient>
                    </defs>
                    <Area
                      key={key}
                      type={graphLineStyle}
                      dataKey={key}
                      stroke={stylesVariables.primaryColor}
                      fill={`url(#${gradientId})`}
                      fillOpacity={0.6}
                      strokeWidth={1.5}
                      activeDot={isDot ? <CustomDot color={color} /> : false}
                      isAnimationActive={false}
                    />
                  </>
                );
              }
            })}
            {Object.keys(processedData[0].parameters || {}).map((key, index) => {
              if (showPointRef) {
                const lastDataPoint = processedData[processedData.length - 1];
                let color = '#cc8500';

                if (pointRefIndex) {
                  if (Array.isArray(processedData[0].parameters[key])) {
                    return processedData[0].parameters[key].map((_: any, subIndex: any) => {
                      const colorIndex = subIndex % parameterLevelColors.length;
                      color =
                        subIndex === 0 ? parameterLevelColors[0] : parameterLevelColors[colorIndex];
                      if (pointRefIndex.includes(subIndex)) {
                        return (
                          <ReferenceDot
                            x={lastDataPoint.timestamp}
                            y={
                              lastDataPoint.parameters[parameter.replace('parameters.', '')][
                                subIndex
                              ]
                            }
                            r={5}
                            fill={color}
                            stroke='#fff'
                            key={color + uniqueId()}
                          />
                        );
                      } else {
                        return null;
                      }
                    });
                  }
                } else {
                  const yValue = Array.isArray(
                    lastDataPoint.parameters[parameter.replace('parameters.', '')]
                  )
                    ? lastDataPoint.parameters[parameter.replace('parameters.', '')][0]
                    : lastDataPoint.parameters[parameter.replace('parameters.', '')];

                  return (
                    <ReferenceDot
                      x={lastDataPoint.timestamp}
                      y={yValue}
                      r={5}
                      fill={color}
                      stroke='#fff'
                      key={color + uniqueId()}
                    />
                  );
                }
              } else {
                return null;
              }
            })}

            {hasBrush && (
              <Brush
                dataKey='timestamp'
                height={20}
                stroke={stylesVariables.primaryColor}
                tickFormatter={(timestamp) => getTickFormatter(timestamp)}
                className={getBrushClass()}
                startIndex={startIndex !== -1 ? startIndex : 0}
                endIndex={endIndex !== -1 ? endIndex : processedData.length - 1}
                onChange={(indices) => {
                  const newStartIndex = indices.startIndex ?? null;
                  const newEndIndex = indices.endIndex ?? null;

                  brushIndicesRef.current = {
                    startIndex: newStartIndex,
                    endIndex: newEndIndex,
                  };
                  hasBrushBeenUsed.current = true;
                }}
              >
                <AreaChart key={urlParams.tab} data={processedData}>
                  <CartesianGrid />
                  <XAxis hide dataKey='timestamp' ticks={[]} />
                  <YAxis domain={['dataMin', 'dataMax']} hide />
                  <Area
                    type={graphLineStyle}
                    key={urlParams.tab}
                    dataKey={parameter}
                    stroke='#ddd'
                    fill='#ddd'
                    isAnimationActive={false}
                  />
                </AreaChart>
              </Brush>
            )}
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default Graph;
