import { useDispatch, useSelector } from 'react-redux';
import { useLogoutMutation } from 'services/auth.service';
import { Dropdown, MenuProps, Space } from 'antd';
import { setUser } from 'store/slices/auth.slice';
import styles from './Profile.module.scss';

import { ReactComponent as ArrowDown } from 'assets/icons/down-arrow.svg';
import { useNavigate } from 'react-router-dom';
import { setSelectedTenant, setTenants } from 'store/slices/tenants.slice';
import useTranslation from 'hooks/useTranslations';

const Profile = () => {
  const { user } = useSelector((state: any) => state.auth);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [logout] = useLogoutMutation();

  const { translate } = useTranslation();

  const onLogout = () => {
    try {
      logout({});
      dispatch(setUser(null));
      dispatch(setTenants([]));
      dispatch(setSelectedTenant(null));
      navigate('/login');
    } catch (error: any) {
      console.log('Error logging out', error);
    }
  };

  const items: MenuProps['items'] = [
    {
      label: translate('tr_logout'),
      key: '1',
    },
    {
      label: translate('tr_reset_password'),
      key: '2',
    },
  ];

  const onClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case '1':
        onLogout();
        break;
      case '2':
        navigate('/profile');
        break;
      default:
        break;
    }
  };

  if (!user) {
    return null;
  }

  const userInitials = (user.firstName[0] + user.lastName[0]).toUpperCase();

  return (
    <Dropdown menu={{ items, onClick }}>
      <span onClick={(e) => e.preventDefault()}>
        <Space>
          <div className={styles.profile}>
            <div className={styles.avatar}>{userInitials}</div>
            <div className={styles.userInfo}>
              <div className={styles.userName}>{user.firstName + ' ' + user.lastName}</div>
              <div className={styles.userEmail}>{user.email}</div>
            </div>
          </div>
          <ArrowDown className={styles.profileArrowIcon} />
        </Space>
      </span>
    </Dropdown>
  );
};

export default Profile;
