import { List, Result, Space, Switch } from 'antd';
import { ColumnGroupType, ColumnType } from 'antd/es/table';
import Table from 'components/common/Table/Table';
import Spinner from 'components/common/Spin/Spin';
import { DeviceStatus } from 'components/specific/DeviceInfo/DeviceStatus/DeviceStatus';
import { useCallback, useEffect, useState } from 'react';
import {
  useEditActuatorMutation,
  useGetActuatorsQuery,
  useSetActuatorMutation,
} from 'services/actuators.service';
import { useAppSelector } from 'store/hooks';
import { tenantSelector } from 'store/slices/tenants.slice';
import styles from './Actuators.module.scss';
import { EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { CustomStyledInput } from 'components/common/Input/Input.styles';
import { ReactComponent as ActuatorIllustration } from 'assets/icons/actuators_illustration2.svg';
import useTranslation from 'hooks/useTranslations';
import { createDeviceUtils } from 'utils/device.utils';
import { random } from 'lodash';
import useIsMobile from 'hooks/useIsMobile';

interface ActuatorDetailes {
  actuator: string;
  value: number;
  pending: boolean;
}
interface ActuatorsList {
  deviceId: string;
  timestamp: number;
  deviceName: string | null;
  actuators_details: ActuatorDetailes[];
}

interface ActuatorStates {
  [key: string]: number[];
}

interface PendingStates {
  [key: string]: boolean[];
}

interface editDeviceIndex {
  [key: string]: number;
}

const Valves = () => {
  const tenantId = useAppSelector(tenantSelector).selectedTenant?.tenantId as string;
  const {
    data: actuators,
    error,
    isLoading,
  } = useGetActuatorsQuery({ tenantId }, { skip: !tenantId, refetchOnMountOrArgChange: true }) as {
    data: ActuatorsList[] | undefined;
    error: any;
    isLoading: boolean;
  };

  const [actuatorsStates, setActuatorsStates] = useState<ActuatorStates>({});
  const [pendingStates, setPendingStates] = useState<PendingStates>({});
  const [setActuators] = useSetActuatorMutation();
  const [editActuator] = useEditActuatorMutation();
  const [editDeviceIndex, setEditDeviceIndex] = useState<editDeviceIndex | null>(null);
  const [editValue, setEditValue] = useState<string>('');
  const { translate } = useTranslation();
  const deviceUtils = createDeviceUtils(translate);

  useEffect(() => {
    if (actuators) {
      const initialStates: ActuatorStates = {};

      actuators.forEach((actuator) => {
        initialStates[actuator.deviceId] = actuator.actuators_details.map((detail) => detail.value);
      });
      setActuatorsStates(initialStates);
    }
  }, [actuators]);

  const isMobile = useIsMobile();

  const updateActuatorsValues = useCallback(
    async (devEui: string | null, newState: number[]) => {
      if (!tenantId || !devEui) return;
      try {
        const data = [...newState];
        const actuator_names =
          actuators
            ?.find((actuator) => actuator.deviceId === devEui)
            ?.actuators_details?.map((actuator) => actuator.actuator) || [];

        setActuators({ tenantId, devEui, data, actuator_names }).unwrap();
      } catch (err) {
        console.error('Failed to save actuator:', err);
      }
    },
    [actuators, setActuators, tenantId]
  );

  const onSwitchChange = useCallback(
    async (actuator: number[], deviceId: string, index: number) => {
      setActuatorsStates((prevState) => {
        const newState = { ...prevState };
        newState[deviceId] = [...actuator];
        newState[deviceId][index] = Number(actuator[index]) !== 0 ? 0 : 1;
        updateActuatorsValues(deviceId, newState[deviceId]);
        setPendingStates((prevState) => {
          const newPendingStates = { ...prevState };
          const pendingStatesValues = newPendingStates[deviceId] || [];
          pendingStatesValues[index] = true;
          newPendingStates[deviceId] = [...pendingStatesValues];
          return newPendingStates;
        });
        return newState;
      });
    },
    [updateActuatorsValues]
  );

  const handleSaveEdit = () => {
    const devEui = Object.keys(editDeviceIndex as editDeviceIndex)[0];
    if (editDeviceIndex !== null && editValue && tenantId && devEui) {
      editActuator({
        tenantId,
        devEui,
        name: editValue,
        index: editDeviceIndex[devEui],
      }).unwrap();
    }
    setEditDeviceIndex(null);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSaveEdit();
    }
  };

  const handleEditDevice = (index: number, devEui: string, actuatorName: string) => {
    setEditDeviceIndex({ [devEui]: index });
    setEditValue(actuatorName);
  };

  const handleCancelEdit = () => {
    setEditDeviceIndex(null);
  };

  const renderNoActuators = () => {
    return (
      <div className='emptyContainer'>
        <ActuatorIllustration className='actuatorIllustration' />
        <div className='emptyContent'>
          <p className='emptyTitle'>{translate('tr_no_device_actuators1')}</p>
          <p className='emptyDescription'>{translate('tr_no_device_actuators2')}</p>
        </div>
      </div>
    );
  };

  const renderEditField = (devEui: string) => (
    <div className={styles.editContainer}>
      <CustomStyledInput
        value={editValue}
        onChange={(e) => setEditValue(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <Space className={styles.actionIcons}>
        <CheckOutlined className={styles.checkIcon} onClick={handleSaveEdit} />
        <CloseOutlined className={styles.closeIcon} onClick={handleCancelEdit} />
      </Space>
    </div>
  );

  const getFormatedActuators = (actuatorsList: ActuatorsList) => {
    if (!actuators || !actuators.length) return [];

    const actuatorsDevEui = actuatorsList.deviceId;
    // eslint-disable-next-line array-callback-return
    return actuatorsList.actuators_details.map((actuator, index) => {
      let pending = pendingStates[actuatorsDevEui]
        ? pendingStates[actuatorsDevEui][index] || actuator.pending
        : actuator.pending;
      if (actuatorsStates && actuatorsDevEui && actuatorsStates[actuatorsDevEui]) {
        return {
          name: (
            <div className={styles.actuatorEditIcon}>
              {editDeviceIndex && editDeviceIndex[actuatorsDevEui] === index ? (
                renderEditField(actuatorsDevEui)
              ) : (
                <>
                  <span>{actuator.actuator}</span>
                  <EditOutlined
                    className={styles.editDevice}
                    onClick={() => handleEditDevice(index, actuatorsDevEui, actuator.actuator)}
                  />
                </>
              )}
            </div>
          ),
          status: <DeviceStatus device={Number(actuatorsList.timestamp)} />,
          lastSeen: deviceUtils.getDeviceLastSeen(Number(actuatorsList.timestamp), false),
          state: (
            <div className={styles.pendingActuator}>
              {pending ? <div className={styles.pendingRow}>{translate('tr_pending')}</div> : ''}
              <Switch
                checked={!!actuatorsStates[actuatorsDevEui][index]}
                onChange={() =>
                  onSwitchChange(actuatorsStates[actuatorsDevEui], actuatorsDevEui, index)
                }
                checkedChildren={translate('tr_on')}
                unCheckedChildren={translate('tr_off')}
              />
            </div>
          ),
        };
      }
    });
  };

  const columns: (ColumnGroupType<any> | ColumnType<any>)[] = [
    {
      title: translate('tr_name'),
      dataIndex: 'name',
      width: '30%',
      render: (text: string, record) => {
        return <b>{text}</b>;
      },
    },
    {
      title: translate('tr_status'),
      dataIndex: 'status',
      width: '30%',
    },
    {
      title: translate('tr_last_seen'),
      dataIndex: 'lastSeen',
      width: '30%',
    },
    {
      title: translate('tr_state'),
      dataIndex: 'state',
    },
  ];
  if (!actuators) return null;

  const renderActuatorsDesktop = (actuators: any[]) => {
    return (
      <>
        {actuators.map((actuators: ActuatorsList) => (
          <Table
            data={getFormatedActuators(actuators)}
            columns={columns}
            rowClassName={styles.actuatorRow}
            title={() => <div className={styles.titleRow}>{actuators.deviceName}</div>}
            className={styles.actuatorsTable}
          />
        ))}
      </>
    );
  };

  const renderActuatorsMobile = (actuators: any) => {
    if (actuators?.length && Object.keys(actuatorsStates)?.length)
      return (
        <>
          {actuators.map((actuators: ActuatorsList) => {
            return (
              <div className={styles.actuatorList}>
                <div className={styles.actuatorHeader}>{actuators.deviceName}</div>
                <div className={styles.actuatorContent}>
                  <List
                    dataSource={getFormatedActuators(actuators)}
                    renderItem={(actuatorItem) => {
                      if (!actuatorItem) return null;

                      return (
                        <List.Item key={actuatorItem.lastSeen || random(0, 1000)}>
                          <List.Item.Meta
                            avatar={<DeviceStatus device={Number(actuators.timestamp)} />}
                            title={<span>{actuatorItem.name}</span>}
                            description={actuatorItem.lastSeen}
                            className={styles.actuatorItem}
                          />
                          <div>{actuatorItem.state}</div>
                        </List.Item>
                      );
                    }}
                  />
                </div>
              </div>
            );
          })}
        </>
      );
  };

  const renderActuators = (actuators: any) => {
    if (actuators && actuators.length > 0) {
      if (isMobile) {
        return renderActuatorsMobile(actuators);
      } else {
        return renderActuatorsDesktop(actuators);
      }
    }
  };

  return (
    <>
      {(!actuators || actuators.length === 0) && renderNoActuators()}
      <Spinner spinning={isLoading}>{!error && renderActuators(actuators)}</Spinner>
      {error && <Result status='500' subTitle={translate('tr_no_actuators')} />}
    </>
  );
};

export default Valves;
