export const dateRangeOptions = {
  lastDayOptionId: 'lastDayOptionId',
  lastWeekOptionId: 'lastWeekOptionId',
  lastMonthOptionId: 'lastMonthOptionId',
  dateRangeOptionId: 'dateRangeOptionId',
};

export const deviceParametersV2 = {
  TEMPERATURE: 'temperature',
  HUMIDITY: 'humidity',
  LUMINOSITY: 'luminosity',
  PRESSURE: 'pressure',
  SOIL_MOISTURE: 'soilMoisture',
  AIR_HUMIDITY: 'airHumidity',
  BATTERY: 'battery',
  FLOW_RATES: 'flowrates',
};
export const deviceParametersV3 = {
  TEMPERATURE: 'temperatures',
  HUMIDITY: 'humidities',
  LUMINOSITY: 'luminosities',
  PRESSURE: 'pressures',
  SOIL_MOISTURE: 'soilMoistures',
  AIR_HUMIDITY: 'airHumidities',
  BATTERY: 'battery',
  FLOW_RATES: 'flowrates',
};

export const deviceParametersGroup = {
  ACTUATORS: 'actuators',
  BATTERY: 'battery',
};

export const parameterLevelColors = [
  '#00873E', // Primary Green
  '#71A92C', // Corn green
  '#AADB1E', // Bright green
  '#A2E4B8', // Mint green
  '#0E4732', // Spring green
];

export const unitTypes = {
  LUX: 'Lux',
  PA: 'Pa',
  PERCENT: '%',
  CELSIUS: '\u00B0C',
  FLOW_RATE: 'L/min',
};

export const deviceTypes = {
  SENSOR: 'sensor',
  SWITCH: 'switch',
};

export const timeSeconds = {
  QUARTER_HOUR: 3600 / 4,
  ONE_DAY: 86400,
  ONE_WEEK: 604800,
  ONE_MONTH: 2592000,
};

export const powerStatus = {
  BATTERY_EMPTY: 'BATTERY_EMPTY',
  BATTERY_LOW: 'BATTERY_LOW',
  BATTERY_MEDIUM: 'BATTERY_MEDIUM',
  BATTERY_HIGH: 'BATTERY_HIGH',
  POWER_NO_INFO: 'POWER_NO_INFO',
  POWER_CABLE: 'POWER_CABLE',
};

export const BATTERY_WIDTH = 16;

export const RESOLUTIONS = {
  RAW: 'RAW',
  TEN_MIN: 'TEN_MIN',
  HOURLY: 'HOURLY',
  DAILY: 'DAILY',
};

export const DEVICE_VERSION = {
  V2: 'V2',
  V3: 'V3',
};
