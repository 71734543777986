import {
  deviceParametersV3,
  deviceParametersV2,
  RESOLUTIONS,
  timeSeconds,
  unitTypes,
} from 'config/constant';
import dayjs from 'dayjs';
import { DateRangeUnix } from 'interfaces/Time.interface';
import { DeviceEvent } from 'interfaces/devices/DeviceEvents.interface';

/**
 * Returns the appropriate unit for a given parameter.
 * @param {string} parameter - The parameter for which to determine the unit.
 * @returns {string} The unit associated with the parameter, or an empty string if no match is found.
 */
export const getUnit = (parameter: string): string => {
  if (
    parameter.includes(deviceParametersV3.LUMINOSITY) ||
    parameter.includes(deviceParametersV2.LUMINOSITY)
  ) {
    return unitTypes.LUX;
  }
  if (
    parameter.includes(deviceParametersV3.PRESSURE) ||
    parameter.includes(deviceParametersV2.PRESSURE)
  ) {
    return unitTypes.PA;
  }
  if (
    parameter.includes(deviceParametersV3.SOIL_MOISTURE) ||
    parameter.includes(deviceParametersV3.AIR_HUMIDITY) ||
    parameter.includes(deviceParametersV3.BATTERY) ||
    parameter.includes(deviceParametersV2.SOIL_MOISTURE) ||
    parameter.includes(deviceParametersV2.AIR_HUMIDITY) ||
    parameter.includes(deviceParametersV2.BATTERY)
  ) {
    return unitTypes.PERCENT;
  }
  if (
    parameter.includes(deviceParametersV3.TEMPERATURE) ||
    parameter.includes(deviceParametersV2.TEMPERATURE)
  ) {
    return unitTypes.CELSIUS;
  }
  if (
    parameter.includes(deviceParametersV3.FLOW_RATES) ||
    parameter.includes(deviceParametersV2.FLOW_RATES)
  ) {
    return unitTypes.FLOW_RATE;
  }

  return '';
};

export const getGraphLeftMargin = (parameter: string): number => {
  if (
    parameter.includes(deviceParametersV3.LUMINOSITY) ||
    parameter.includes(deviceParametersV2.LUMINOSITY)
  ) {
    return 0;
  }
  if (
    parameter.includes(deviceParametersV3.PRESSURE) ||
    parameter.includes(deviceParametersV2.PRESSURE)
  ) {
    return 30;
  }
  if (
    parameter.includes(deviceParametersV3.SOIL_MOISTURE) ||
    parameter.includes(deviceParametersV3.AIR_HUMIDITY) ||
    parameter.includes(deviceParametersV3.BATTERY) ||
    parameter.includes(deviceParametersV2.SOIL_MOISTURE) ||
    parameter.includes(deviceParametersV2.AIR_HUMIDITY) ||
    parameter.includes(deviceParametersV2.BATTERY)
  ) {
    return 0;
  }
  if (
    parameter.includes(deviceParametersV3.TEMPERATURE) ||
    parameter.includes(deviceParametersV2.TEMPERATURE)
  ) {
    return 0;
  }
  if (
    parameter.includes(deviceParametersV3.FLOW_RATES) ||
    parameter.includes(deviceParametersV2.FLOW_RATES)
  ) {
    return 0;
  }
  return 0;
};

/**
 * Formats a Unix timestamp based on the provided date range.
 * The format changes depending on the duration of the date range:
 * - Less than or equal to 1 day: 'HH:mm'
 * - Less than or equal to 1 week: 'ddd HH:mm'
 * - Less than or equal to 1 month: 'DD/MM'
 * - More than 1 month: 'DD/MM/YYYY'
 * @param {number} timestamp - The Unix timestamp to format.
 * @param {DateRangeUnix} tooltipDateRange - The start and end of the date range.
 * @returns {string} The formatted date string.
 */
export const formatTick = (timestamp: number, tooltipDateRange: DateRangeUnix): string => {
  const { startDate, endDate } = tooltipDateRange;
  const duration = endDate - startDate;

  if (duration <= timeSeconds.ONE_DAY) {
    return dayjs.unix(timestamp).format('HH:mm');
  } else if (duration <= timeSeconds.ONE_WEEK) {
    return dayjs.unix(timestamp).format('ddd HH:mm');
  } else if (duration <= timeSeconds.ONE_MONTH) {
    return dayjs.unix(timestamp).format('DD/MM');
  }

  return dayjs.unix(timestamp).format('DD/MM/YYYY');
};

/**
 * Determines the appropriate CSS class for a brush component based on the duration of a date range.
 * The class varies depending on the length of the date range:
 * @param {DateRangeUnix} tooltipDateRange - The start and end of the date range.
 * @returns {string} The CSS class name.
 */
export const getBrushClass = (tooltipDateRange: DateRangeUnix): string => {
  const { startDate, endDate } = tooltipDateRange;
  const duration = endDate - startDate;

  if (duration <= timeSeconds.ONE_DAY) {
    return 'last-day-brush';
  } else if (duration <= timeSeconds.ONE_WEEK) {
    return 'last-week-brush';
  } else if (duration <= timeSeconds.ONE_MONTH) {
    return 'last-month-brush';
  }

  return 'longer-period-brush';
};

export const getResolutionBasedOnInterval = (
  zoomDomain: [number, number] | null,
  minResolution?: string
) => {
  let resolution = null;

  if (!zoomDomain) {
    return resolution;
  }
  const from = zoomDomain[0];
  const to = zoomDomain[1];
  const interval = (to - from) / (60 * 60 * 24);
  if (interval >= 10) {
    resolution = RESOLUTIONS.DAILY;
  } else if (interval < 10 && interval >= 0.8) {
    resolution = RESOLUTIONS.HOURLY;
  } else if (interval < 0.8 && interval > 0.2) {
    resolution = RESOLUTIONS.TEN_MIN;
  } else {
    resolution = RESOLUTIONS.RAW;
  }
  if (minResolution) {
    if (isResolutionBigger(minResolution, resolution)) {
      resolution = minResolution;
    }
  }

  return resolution;
};

export const getMinResolutionLimitation = (startDate: number, endDate: number) => {
  const intervalDays = (endDate - startDate) / (60 * 60 * 24);
  if (intervalDays >= 4 && intervalDays < 30) {
    return RESOLUTIONS.TEN_MIN;
  } else if (intervalDays >= 30 && intervalDays >= 0.8) {
    return RESOLUTIONS.HOURLY;
  }
  return RESOLUTIONS.RAW;
};

export const isResolutionBigger = (resolution: string | null, newResolution: string | null) => {
  if (!resolution || !newResolution) {
    return false;
  }
  if (resolution === RESOLUTIONS.DAILY && newResolution !== RESOLUTIONS.DAILY) {
    return true;
  } else if (
    resolution === RESOLUTIONS.HOURLY &&
    newResolution !== RESOLUTIONS.DAILY &&
    newResolution !== RESOLUTIONS.HOURLY
  ) {
    return true;
  } else if (resolution === RESOLUTIONS.TEN_MIN && newResolution === RESOLUTIONS.RAW) {
    return true;
  } else {
    return false;
  }
};

export const getResolution = async (
  zoomDomain: [number, number] | null,
  fetchData?: (
    from: number,
    to: number,
    resolution: string | null
  ) => Promise<DeviceEvent[]> | string
) => {
  let resolution = getResolutionBasedOnInterval(zoomDomain);
  if (!zoomDomain || !fetchData) {
    return null;
  }
  if (fetchData) {
    let data = await fetchData(zoomDomain[0], zoomDomain[1], resolution);
    if (data.length < 2) {
      while (data.length < 2 && resolution !== RESOLUTIONS.RAW) {
        resolution = getNextFinerResolution(resolution);
        data = await fetchData(zoomDomain[0], zoomDomain[1], resolution);
      }
    }
  }
  return resolution;
};

export const getNextFinerResolution = (resolution: string | null) => {
  if (resolution === RESOLUTIONS.DAILY) {
    return RESOLUTIONS.HOURLY;
  } else if (resolution === RESOLUTIONS.HOURLY) {
    return RESOLUTIONS.TEN_MIN;
  } else if (resolution === RESOLUTIONS.TEN_MIN) {
    return RESOLUTIONS.RAW;
  } else return null;
};
