import { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { langageSelector, setLanguage } from '../store/slices/language.slice';
import { useSetLanguagePerUserMutation } from 'services/user.service';
import { authSelector } from 'store/slices/auth.slice';
import 'dayjs/locale/ro';
import 'dayjs/locale/hu';
import 'dayjs/locale/en';
import dayjs from 'dayjs';

const useTranslation = (translationsPath = '/translations') => {
  const dispatch = useDispatch();
  const language = useSelector(langageSelector).language;
  const [translations, setTranslations] = useState<{ [key: string]: string }>({});
  const [setLanguagePerUser] = useSetLanguagePerUserMutation();
  const user = useSelector(authSelector).user;
  const userLanguage = user?.language;
  dayjs.locale(language || userLanguage);

  const getBrowserLanguage = () => {
    return navigator.language.split('-')[0] || 'fr';
  };

  const translate = useCallback(
    (key: string | number, placeholders: { [key: string]: string } = {}) => {
      let text = translations[key] || key.toString();
      if (typeof text === 'string') {
        Object.keys(placeholders).forEach((placeholder) => {
          text = text.replace(`{{${placeholder}}}`, placeholders[placeholder]);
        });
      }
      return text;
    },
    [translations]
  );

  const changeLanguage = useCallback(
    (lang: string) => {
      if (user) {
        setLanguagePerUser({
          id: user.id,
          language: lang,
        });
      }
      dispatch(setLanguage(lang));
    },
    [dispatch, setLanguagePerUser, user]
  );

  const getLanguageTitle = (lang: string | null) => {
    switch (lang) {
      case 'en':
        return 'EN';
      case 'ro':
        return 'RO';
      case 'hu':
        return 'HU';
      default:
        return '';
    }
  };

  useEffect(() => {
    const loadTranslations = async (lang: string) => {
      try {
        const response = await fetch(`${translationsPath}/${lang}.json`);
        const data = await response.json();
        setTranslations(data);
      } catch (error) {
        console.error(`Error loading translations for language ${lang}:`, error);
      }
    };

    if (userLanguage) {
      if (language !== userLanguage) {
        if (language) {
          // If the user has a language set, but on login page i change the language and then login, set that language
          changeLanguage(language);
          loadTranslations(language);
        } else {
          loadTranslations(userLanguage);
          dispatch(setLanguage(userLanguage));
        }
      } else {
        loadTranslations(userLanguage);
      }
    } else if (language) {
      loadTranslations(language);
    } else {
      const browserLanguage = getBrowserLanguage();
      loadTranslations(browserLanguage);
      dispatch(setLanguage(browserLanguage));
    }
  }, [userLanguage, language, dispatch, translationsPath, changeLanguage]);

  return { translate, language, changeLanguage, getLanguageTitle };
};

export default useTranslation;
