import { useCallback, useState } from 'react';
import { DeviceEvent } from 'interfaces/devices/DeviceEvents.interface';
import { useGetDeviceEventsMutation } from 'services/deviceEvents.service';
import dayjs from 'dayjs';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';

/**
 * Custom hook to fetch device events data and manage its state, with caching support.
 *
 * @param {string} deviceId - The unique identifier of the device whose events are to be fetched.
 *
 * @returns {[DeviceEvent[], boolean, (from: number, to: number, resolution: string | null, cache?: boolean) => Promise<DeviceEvent[]>, DeviceEvent[] | null, boolean]}
 * - A tuple containing:
 *  1. `eventsList` - The list of device events fetched from the API.
 *  2. `loading` - A boolean indicating whether the main data is currently being loaded.
 *  3. `fetchData` - A function to trigger the fetching of device events within a specified time range, with optional caching.
 *  4. `cachedEventsList` - The cached list of device events fetched with a finer resolution.
 *  5. `loadingCachedData` - A boolean indicating whether the cached data is currently being loaded.
 */

/**
 * Modified fetch function to handle the specific response type from `getDeviceEvents`.
 */
const debouncedFetch = async (
  getDeviceEvents: (params: {
    deviceId: string;
    from: number;
    to: number;
    resolution: string;
  }) => Promise<{ data?: any[]; error?: FetchBaseQueryError | SerializedError }>,
  deviceId: string,
  from: number,
  to: number,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setEventsList: React.Dispatch<React.SetStateAction<any[]>>,
  resolution: string | null,
  setCachedEventsList?: React.Dispatch<React.SetStateAction<any[]>>,
  setCachedLoading?: React.Dispatch<React.SetStateAction<boolean>>
): Promise<DeviceEvent[]> => {
  if (!resolution || !from || !to || !deviceId) {
    console.warn('Parameters missing! fetch aborted.');
    return [];
  }

  if (setCachedLoading) {
    setCachedLoading(true);
  } else {
    setLoading(true);
  }

  const response = await getDeviceEvents({
    deviceId,
    from,
    to,
    resolution,
  });

  const { data, error } = response;

  if (error) {
    console.error('Error fetching data:', error);
    if (setCachedLoading) setCachedLoading(false);
    else setLoading(false);
    return [];
  }

  const eventList = data
    ? data.map((event) => ({
        ...event,
        timestamp: dayjs.unix(event.timestamp).format('YYYY-MM-DD HH:mm:ss'),
      }))
    : [];

  if (setCachedEventsList) {
    setCachedEventsList(eventList);
    setCachedLoading && setCachedLoading(false);
  } else {
    setEventsList(eventList);
    setLoading(false);
  }

  return eventList;
};

const useFetchData = (
  deviceId: string
): [
  DeviceEvent[],
  boolean,
  (from: number, to: number, resolution: string | null, cache?: boolean) => Promise<DeviceEvent[]>,
  DeviceEvent[],
  boolean,
] => {
  const [getDeviceEvents] = useGetDeviceEventsMutation();
  const [eventsList, setEventsList] = useState<DeviceEvent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [cachedEventsList, setCachedEventsList] = useState<DeviceEvent[]>([]);
  const [loadingCachedData, setLoadingCachedData] = useState<boolean>(false);

  const fetchData = useCallback(
    async (
      from: number,
      to: number,
      resolution: string | null,
      cache = false
    ): Promise<DeviceEvent[]> => {
      const deviceData = await debouncedFetch(
        getDeviceEvents,
        deviceId,
        from,
        to,
        setLoading,
        setEventsList,
        resolution,
        cache ? setCachedEventsList : undefined,
        cache ? setLoadingCachedData : undefined
      );
      return deviceData;
    },
    [deviceId, getDeviceEvents]
  );
  return [eventsList, loading, fetchData, cachedEventsList, loadingCachedData];
};

export default useFetchData;
