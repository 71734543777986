import Table from 'components/common/Table/Table';
import { useCallback, useEffect, useState } from 'react';
import { useGetDevicesQuery } from 'services/devices.service';
import { Device } from 'interfaces/devices/Devices.interface';
import { ColumnGroupType, ColumnType } from 'antd/es/table';
import { useAppSelector } from 'store/hooks';
import { setDevices } from 'store/slices/devices.slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { List, Result } from 'antd';
import Spinner from 'components/common/Spin/Spin';
import { tenantSelector } from 'store/slices/tenants.slice';

import { DeviceStatus } from 'components/specific/DeviceInfo/DeviceStatus/DeviceStatus';
import { PowerStatus } from 'components/specific/DeviceInfo/PowerStatus/PowerStatus';
import { createDeviceUtils } from 'utils/device.utils';
import { ReactComponent as SensorsIllustration } from 'assets/icons/sensors_illustration.svg';
import useTranslations from 'hooks/useTranslations';

import useIsMobile from 'hooks/useIsMobile';
import { random } from 'lodash';
import styles from './Devices.module.scss';

const DevicesView = () => {
  const [tableDeviceList, setTableDeviceList] = useState<any[]>([{}]);
  const tenantId = useAppSelector(tenantSelector).selectedTenant?.tenantId as string;
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { translate } = useTranslations();
  const deviceUtils = createDeviceUtils(translate);
  const isMobile = useIsMobile();

  const {
    data: devicesData,
    error,
    isLoading,
  } = useGetDevicesQuery(
    { tenantId },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const getFormatedDevices = useCallback(
    (devicesList: Device[]): any[] => {
      return devicesList.map((device) => {
        return {
          id: device.devEui,
          name: device.name,
          description: device.description,
          status: <DeviceStatus device={device} />,
          lastSeen: deviceUtils.getDeviceLastSeen(device, false),
          battery: <PowerStatus device={device} />,
        };
      });
    },
    [deviceUtils]
  );

  useEffect(() => {
    if (devicesData) {
      setTableDeviceList(getFormatedDevices(devicesData));
      dispatch(setDevices(devicesData));
    }
  }, [devicesData, dispatch, getFormatedDevices]);

  const columns: (ColumnGroupType<any> | ColumnType<any>)[] = [
    {
      title: translate('tr_name'),
      dataIndex: 'name',
      width: '30%',
      render: (text: string, record) => {
        return <b>{text}</b>;
      },
    },
    {
      title: translate('tr_last_seen'),
      dataIndex: 'lastSeen',
      width: '30%',
    },
    {
      title: translate('tr_status'),
      dataIndex: 'status',
      width: '30%',
    },
    {
      title: translate('tr_power'),
      dataIndex: 'battery',
    },
  ];

  const renderTableDevices = () => {
    return (
      <Table
        data={tableDeviceList}
        columns={columns}
        onRowClick={(record: any) => {
          navigator(`/device?id=${record.id}`);
        }}
      />
    );
  };

  const renderListDevices = () => {
    let devices = getFormatedDevices(devicesData || []);
    return (
      <div className={styles.devicesList}>
        <List
          dataSource={devices}
          renderItem={(device) => {
            if (!device) return null;

            return (
              <List.Item
                key={device.lastSeen || random(0, 1000)}
                onClick={(record: any) => {
                  navigator(`/device?id=${record.id}`);
                }}
              >
                <List.Item.Meta
                  avatar={device.status}
                  title={<span>{device.name}</span>}
                  description={device.lastSeen}
                  className={styles.deviceItem}
                />
                <div>{device.battery}</div>
              </List.Item>
            );
          }}
        />
      </div>
    );
  };

  return (
    <>
      <Spinner spinning={isLoading}>
        {!error && tableDeviceList.length > 0 && (
          <>{isMobile ? renderListDevices() : renderTableDevices()}</>
        )}
      </Spinner>
      {error && <Result status='500' subTitle='No devices found' />}

      {tableDeviceList.length === 0 && !isLoading && !error && (
        <div className='emptyContainer'>
          <SensorsIllustration className='sensorIllustration' />
          <div className='emptyContent'>
            <p className='emptyTitle'>{translate('tr_no_devices_connected')}</p>
            <p className='emptyDescription'>
              {translate('tr_no_devices_message1')} <br />
              {translate('tr_no_devices_message2')}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default DevicesView;
